import { Grid, NumberInput } from '@mantine/core';

import { FormControl, FormListManager, LabelWithTooltip } from 'common/components/ui';
import { useGetDisabledOptions } from 'common/hooks';
import { VideosListSelect } from 'common/components/base';

import { useTranslation } from 'react-i18next';
import { LessonArea } from '../LessonArea/LessonArea';
import { VideoTimeCodes } from './components/VideoTimecodes/VideoTimecodes';
import { createLessonVideoItem } from '../../../FormLessons/FormLessons.constants';

import translations from './translations';

const defaultStyle = theme => ({
  backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2],
  padding: theme.spacing.md,
  borderRadius: theme.radius.md,
});

const getOptions = lessons => {
  const disabledOptions = [];

  lessons.forEach(({ lessonContents }) => {
    lessonContents.forEach(({ lessonContentMetas }) => {
      lessonContentMetas.forEach(({ video }) => {
        if (video) {
          disabledOptions.push(video);
        }
      });
    });
  });

  return disabledOptions;
};

export const LessonContentItem = ({ fieldName, removeTimecode, newVideoItemModel }) => {
  const { t } = useTranslation();
  const disabledOptions = useGetDisabledOptions({ name: 'lessons', getOptions });

  return (
    <Grid>
      <Grid.Col xs={6}>
        <VideosListSelect
          label={
            <LabelWithTooltip
              text={t(translations.fields.video.label)}
              tooltipText={t('tooltips.lesson-content')}
            />
          }
          placeholder={t(translations.fields.video.placeholder)}
          name={`${fieldName}.lessonContentMetas[0].video`}
          disabledOptions={disabledOptions}
          sx={{ Label: { display: 'inline-flex' } }}
          withAsterisk
        />
      </Grid.Col>
      <Grid.Col xs={6}>
        <FormControl
          label={t(translations.fields.videoDuration.label)}
          placeholder={t(translations.fields.videoDuration.placeholder)}
          name={`${fieldName}.duration`}
          inputElement={NumberInput}
          min={0}
        />
      </Grid.Col>

      <Grid.Col xs={12}>
        <LessonArea
          title={t(translations.sections.timeCodes.title)}
          titleOrder={6}
          sx={defaultStyle}
        >
          <FormListManager
            onRemove={removeTimecode}
            title={t(translations.sections.timeCodes.title)}
            maxItems={100}
            titleOrder={6}
            buttonTitle={t(translations.buttons.newTimeCode)}
            buttonColor="dark.1"
            name={`${fieldName}.videoTimecodes`}
            newItemModel={newVideoItemModel ?? createLessonVideoItem('ua')}
            renderComponent={<VideoTimeCodes />}
          />
        </LessonArea>
      </Grid.Col>
    </Grid>
  );
};
