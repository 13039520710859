/* eslint-disable no-underscore-dangle */
import { adaptMetaAPIDataForForm, adaptMetaFormDataForAPI } from 'common/utils';

import {
  patchCourseLessonContentRequest,
  patchCourseLessonTimecodeRequest,
  postCourseLessonContentRequest,
  postCourseLessonTimecodeRequest,
  putCourseLessonContentMetaRequest,
  putCourseLessonMetaRequest,
  putCourseLessonTimecodeMetaRequest,
  patchLessonDelayRequest,
} from 'common/services';
import { LESSON_TYPE_ENUM } from 'common/enums';

const adaptLessonsTimecodeFormDatForAPI = ({ time, videoTimecodeMetas }) => ({
  time,
  videoTimecodeMetas: adaptMetaFormDataForAPI(videoTimecodeMetas),
});

const adaptLessonsContentFormDatForAPI = ({ duration, lessonContentMetas, videoTimecodes }) => ({
  type: LESSON_TYPE_ENUM.VIDEO,
  duration,
  lessonContentMetas: adaptMetaFormDataForAPI(
    lessonContentMetas.map(({ lang, video }) => ({ lang, video })),
  ),
  videoTimecodes: videoTimecodes.map(adaptLessonsTimecodeFormDatForAPI),
});

export const adaptLessonsFormDatForAPI = ({ lessonMetas, lessonContents, position, delay }) => ({
  position,
  lessonMetas: adaptMetaFormDataForAPI(lessonMetas),
  lessonContents: lessonContents.map(adaptLessonsContentFormDatForAPI),
  delay,
});

export const adaptLessonsAPIDataForForm = ({ lessons }, lang) => {
  return {
    lessons: lessons.map(({ id: lessonID, lessonMetas, lessonContents, delay, ...rest }) => ({
      ...rest,
      _meta: {
        toDelete: true,
        toUpdate: true,
      },
      lessonID,
      lessonMetas: adaptMetaAPIDataForForm(lessonMetas).filter(lesson => lesson.lang === lang),
      delay,
      lessonContents: lessonContents.map(
        ({ id: contentID, lessonContentMetas, videoTimecodes, ...restContents }) => {
          return {
            ...restContents,
            _meta: {
              toDelete: true,
              toUpdate: true,
              contentID,
              lessonID,
            },
            lessonContentMetas: adaptMetaAPIDataForForm(lessonContentMetas)
              .map(i => ({
                ...i,
                _meta: {
                  toDelete: true,
                  toUpdate: true,
                  contentID,
                  lessonID,
                },
              }))
              .filter(contentMetaItem => contentMetaItem.lang === lang),
            videoTimecodes: videoTimecodes.map(
              ({ id: timecodeID, videoTimecodeMetas, ...restTimecodes }) => ({
                ...restTimecodes,
                _meta: {
                  toDelete: true,
                  toUpdate: true,
                  timecodeID,
                  contentID,
                  lessonID,
                },
                videoTimecodeMetas: adaptMetaAPIDataForForm(videoTimecodeMetas).filter(
                  timecode => timecode.lang === lang,
                ),
              }),
            ),
          };
        },
      ),
    })),
  };
};

const getItemWithMetaCreate = item => item?._meta?.toCreate;
const getItemWithMetaUpdate = item => item?._meta?.toUpdate;

export const getLessonRequests = ({ lesson, courseID }) => {
  const requests = [];

  const { lessonID, lessonMetas, lessonContents, delay } = lesson;

  const updateLessonDelayRequest = patchLessonDelayRequest({
    courseID,
    lessonID,
    reqData: { delay },
  });
  requests.push(updateLessonDelayRequest);

  const updateLessonMetaRequest = putCourseLessonMetaRequest({
    courseID,
    lessonID,
    reqData: {
      lessonMetas: adaptMetaFormDataForAPI(lessonMetas),
    },
  });
  requests.push(updateLessonMetaRequest);

  const contentToCreate = lessonContents
    .filter(getItemWithMetaCreate)
    .map(adaptLessonsContentFormDatForAPI);

  if (contentToCreate.length) {
    const createTimecodesRequest = postCourseLessonContentRequest({
      courseID,
      lessonID,
      reqData: { lessonContents: contentToCreate },
    });
    requests.push(createTimecodesRequest);
  }

  const contentToUpdate = lessonContents.filter(getItemWithMetaUpdate);

  // Lesson content to update
  contentToUpdate.forEach(contentItem => {
    const {
      _meta: { contentID },
      videoTimecodes,
      lessonContentMetas,
      ...lessonContent
    } = contentItem;

    const updateContentRequest = patchCourseLessonContentRequest({
      courseID,
      lessonID,
      contentID,
      reqData: lessonContent,
    });

    requests.push(updateContentRequest);

    const updateLessonContentMetasRequest = putCourseLessonContentMetaRequest({
      courseID,
      lessonID,
      contentID,
      reqData: {
        lessonContentMetas: adaptMetaFormDataForAPI(
          lessonContentMetas.map(({ video, lang }) => ({ video, lang })),
        ),
      },
    });

    requests.push(updateLessonContentMetasRequest);

    const timecodesToCreate = videoTimecodes
      .filter(getItemWithMetaCreate)
      .map(adaptLessonsTimecodeFormDatForAPI);

    if (timecodesToCreate.length) {
      const createTimecodesRequest = postCourseLessonTimecodeRequest({
        courseID,
        lessonID,
        contentID,
        reqData: { videoTimecodes: timecodesToCreate },
      });
      requests.push(createTimecodesRequest);
    }

    const timecodesToUpdate = videoTimecodes.filter(getItemWithMetaUpdate);
    timecodesToUpdate.forEach(({ _meta: { timecodeID }, videoTimecodeMetas, ...videoTimecode }) => {
      const updateVideoTimecodeRequest = patchCourseLessonTimecodeRequest({
        courseID,
        lessonID,
        contentID,
        timecodeID,
        reqData: videoTimecode,
      });
      const updateVideoTimecodeMetaRequest = putCourseLessonTimecodeMetaRequest({
        courseID,
        lessonID,
        contentID,
        timecodeID,
        reqData: { videoTimecodeMetas: adaptMetaFormDataForAPI(videoTimecodeMetas) },
      });

      requests.push(updateVideoTimecodeMetaRequest);
      requests.push(updateVideoTimecodeRequest);
    });
  });

  return requests;
};
