import { get, post, put, destroy } from 'common/helpers';

export const getS3VideosListRequest = async () => {
  const { data } = await get('/attachment/lms/s3/downloads', {
    baseURL: process.env.REACT_APP_MEDIA_SERVER_DEFAULT_API_URL_PATH,
  });

  return data;
};

export const uploadVideoToS3Request = async ({ requestData, file }) => {
  const { data } = await post('/attachment/s3/downloads/generate-upload-url', requestData, {
    baseURL: process.env.REACT_APP_MEDIA_SERVER_DEFAULT_API_URL_PATH,
  });

  await put(data.uploadUrl, file, {
    ContentType: requestData.contentType,
  });

  return data;
};

export const deleteVideoFromS3Request = async fileName => {
  const { data } = await destroy(`/attachment/s3/downloads/${fileName}`, {
    baseURL: process.env.REACT_APP_MEDIA_SERVER_DEFAULT_API_URL_PATH,
  });

  return data;
};
